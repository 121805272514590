import {React, useState} from 'react';
import { Link } from "react-router-dom";
import arrowNav from '../../../assets/arrowNavbar.png';
const Sections = ({ closeMenu }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

    return (
        <ul >
              <li><Link className="linkA" to={'/'} onClick={closeMenu}>Nosotros</Link></li>
              <li className={`doWhat linkA ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
              <span className="doWhatText linkA">Que hacemos</span>
                <i className={`arrowIcon${isExpanded ? ' expanded' : ''}`} onClick={toggleExpand}>
                  <img src={arrowNav} alt="Flecha desplegable que hacemos" className={`arrowImage ${isExpanded ? 'rotated' : ''}`} />
                </i>
                <ul className={`subMenu ${isExpanded ? 'expanded' : ''}`}>
                  <li>
                    <Link to={'/comunicacion'} className="linkA" onClick={closeMenu}>
                      Comunicación
                    </Link>
                  </li>
                  <div className='divBar'></div>
                  <li>
                    <Link to={'/marketing'} className="linkA" onClick={closeMenu}>
                      Marketing digital
                    </Link>
                  </li>
                </ul>
              </li>
              <li><Link to={'/contacto'} onClick={closeMenu}><button className="buttonContact">Contacto</button></Link></li>
        </ul>
    );
}

export default Sections;
