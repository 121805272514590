import React from 'react';
import hands from '../../../assets/hands.png';
import people from '../../../assets/people.png';
import estadistic from '../../../assets/estadistic.png';
import analytics from '../../../assets/analytics.png';

const Definitions = () => {
    return (
        <div className='definitions'>
            <div className="content-def">
                <section>
                    <img src={hands} alt="" />
                </section>
                <section className='textdef'>
                    <h6>Confianza</h6>
                    <p>La confianza son los cimientos de toda comunicación.</p>
                </section>
            </div>
            <div className="content-def">
                <section>
                    <img src={people} alt="" />
                </section>
                <section className='textdef'>
                    <h6>Estrategia y planificación</h6>
                    <p>Nuestro equipo se ecargará de idear la mejor solucion.</p>
                </section>
            </div>
            <div className="content-def">
                <section>
                    <img src={estadistic} alt="" />
                </section>
                <section className='textdef'>
                    <h6>Implementación y ejecución</h6>
                    <p>Llevaremos a cabo la mejor idea para obtener los mejores resultados.</p>
                </section>
            </div>
            <div className="content-def">
                <section>
                    <img src={analytics} alt="" />
                </section>
                <section className='textdef'>
                    <h6>Monitoreo y análisis de resultados</h6>
                    <p>La mejor manera de corroborra el exito es midiéndolo.</p>
                </section>
            </div>
        </div>
    );
}

export default Definitions;
