import React from 'react';
import comunic from '../../../assets/comunication.png';
import Definitions from '../Definitons/Definitions';
import CardsServices from '../CardsServices/CardsServices'
import img1 from "../../../assets/comunicacion1.png";
import img2 from "../../../assets/comunicacion2.png";
import img3 from "../../../assets/comunicacion3.png";
import img4 from "../../../assets/comunicacion4.png";
import img5 from "../../../assets/comunicacion5.png";

const Communication = () => {
    const cardsComunication = [
        {
          title: "Comunicación Interna y Externa",
          description:
            "Diseño de estrategias y planificación de dispositivos comunicacionales dinámicos y adaptables a la estructura de la organización.",
          img: img1,
        },
        {
          title: "Comunicación de Crisis",
          description:
            "Plan de acción y programas de prevención de situaciones de crisis para la preservación de la imagen pública de la empresa o institución.",
          img: img2,
        },
        {
          title: "Relaciones con la comunidad",
          description:
            "Diseño de estrategias de comunicación orientadas a construir una imagen de empresa o institución vinculada con la comunidad.",
          img: img3,
        },
        {
          title: "Consultoría y asesorías",
          description:
            "Focus group, encuestas, investigación de campo e informes para proyectos corporativos y campañas políticas.",
          img: img4,
        },
        {
          title: "Producción y realización audiovisual",
          description:
            "Gestión integral de piezas audiovisuales: Spots, contenidos, microdocumentales, informes, material institucional.",
          img: img5,
        },
      ];
    return (
        <>
            <div className='presentation-doWo'>
                <div className='presentation'>
                    <img className='imagePR' src={comunic} alt="Imagen principal servicios de marketing" />
                </div>
                <div className="container-pres">
                    <div className="separation">
                        <p>Que hacemos / Comunicación</p>
                    </div>
                    <div className="contentP">
                        <div className='title-presentation'>
                            <h2>Comunicación</h2>
                        </div>
                        <div className='text-presentation'>
                            <p>Generar dinámicas y proyectos de  marketing, sin ornamentos, que se traduzcan en acciones concretas y efectivas para nuestros clientes.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Definitions/>
            <CardsServices  cards={cardsComunication}/>
        </>
    );
}

export default Communication;
