import React, { useEffect } from "react";

//Estilos
import "../styles/styles.css";

//Rutas de navegación
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

//Componentes estáticos
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Whatsapp from "./Whatsapp/Whatsapp";

//Paginas
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Marketing from "./pages/Marketing/Marketing";
import Communication from "./pages/Communication/Communication";

/*Lógica del header */
const body = document.body;
let lastScroll = 0;
window.addEventListener("scroll", () => {
  const currentScroll = window.pageYOffset;

  if (currentScroll <= 0) {
    body.classList.remove("scroll-up");
  }
  if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
    body.classList.remove("scroll-up");
    body.classList.add("scroll-down");
  }
  if (currentScroll < lastScroll && body.classList.contains("scroll-down")) {
    body.classList.remove("scroll-down");
    body.classList.add("scroll-up");
  }
  lastScroll = currentScroll;
});

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};
const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="contacto" element={<Contact />} />
          <Route path="marketing" element={<Marketing />} />
          <Route path="comunicacion" element={<Communication />} />
        </Routes>
        <Whatsapp />
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
