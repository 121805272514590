import React from "react";
import Definitions from "../Definitons/Definitions";
import imageMarkt from "../../../assets/imageMarketing.png";
import CardsServices from "../CardsServices/CardsServices";

import img1 from "../../../assets/marketing1.png";
import img2 from "../../../assets/marketing2.png";
import img3 from "../../../assets/marketing3.png";
import img4 from "../../../assets/marketing4.png";

const Marketing = () => {
  const cardsMarketing = [
    {
      title: "Social media",
      description:
        "Promovemos tu marca, productos y servicios, así como generamos y mantenemos una reputación positiva.",
      img: img1,
    },
    {
      title: "Publicidad digital",
      description:
        "Uso estratégico de anuncios y contenido patrocinado en plataformas digitales para alcanzar a los usuarios en su experiencia en línea.",
      img: img2,
    },
    {
      title: "Campañas on y offline",
      description:
        "Estrategias de marketing que se llevan a cabo fuera del entorno digital y se centran en canales y medios tradicionales. Estrategias de marketing que se llevan dentro del entorno digital y utilizan canales y medios digitales para promover tu marca, producto o servicio.",
      img: img3,
    },
    {
      title: "Gestión de sitios web y tiendas online",
      description:
        "Gestionamos, administramos y mantenemos tu sitio web para garantizar su funcionamiento eficiente y actualizado.",
      img: img4,
    },
  ];

  return (
    <>
      <div className="presentation-doWo">
        <div className="presentation">
          <img
            className="imagePR"
            src={imageMarkt}
            alt="Imagen principal servicios de marketing"
          />
        </div>
        <div className="container-pres">
          <div className="separation">
            <p>Que hacemos / Marketing digital</p>
          </div>
          <div className="contentP">
            <div className="title-presentation">
              <h2>Marketing digital</h2>
            </div>
            <div className="text-presentation">
              <p>
                Generar dinámicas y proyectos de marketing, sin ornamentos, que
                se traduzcan en acciones concretas y efectivas para nuestros
                clientes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Definitions />
      <CardsServices cards={cardsMarketing} />
    </>
  );
};

export default Marketing;
