import React from "react";

import Initial from "./Initial/Initial";
import WeMNM from "./WeMNM/WeMNM";
import Brands from "./Brands/Brands";
import { QueHacemos} from "./QueHacemos/QueHacemos";


const About = () => {
  return (
    <main>
      <Initial />
      <WeMNM />
      <QueHacemos/>
      <Brands />
    </main>
  );
};

export default About;
