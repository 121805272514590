import React from 'react';
import SumMinimal from './SumMinimal/SumMinimal';
import Formcontact from './Formcontact/Formcontact';

import contact from '../../../assets/imageMarketing.png';

const Contact = () => {
    return (
        <>
        <div className='presentation-contact'>
                <div className='preset-contact'>
                    <img className='imageCN' src={contact} alt="Imagen principal servicios de marketing" />
                </div>
                <div className="contact">
                    <div className="contentC">
                        <div className='title-contact'>
                            <h2>Contacto</h2>
                        </div>
                        <div className='text-contact'>
                            <h4>Estamos para ayudarte, acompañamos tu proyecto.</h4>
                        </div>
                    </div>
                </div>
        </div>
        <SumMinimal/>
        <Formcontact/>
        </>
    );
}

export default Contact;
