import React, { useState, useEffect } from "react";
import MNMGIF from "../../../assets/MNMGIF.gif";
import check from "../../../assets/check.gif";
import triangulos from "../../../assets/trianenvio.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";


export const Approved = ({ filename }) => {
  const [cvFound, setCvFound] = useState(false);
  const [showContainer, setShowContainer] = useState(true);

  const storage = getStorage();
  useEffect(() => {
    const fileRef = ref(storage, `cv_files/${filename}`);
    const checkCvAvailability = async () => {
      let retries = 0;
      const maxRetries = 5;
      const retryDelay = 2000; // 2 segundos
  
      const findCv = async () => {
        try {
          const downloadURL = await getDownloadURL(fileRef);
          // El archivo existe en Firebase Storage
          setCvFound(true);
          setTimeout(() => {
            setShowContainer(false);
          }, 5000);
        } catch (error) {
          // El archivo no existe en Firebase Storage
          retries++;
          if (retries < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            try {
              await findCv();
            } catch (error) {
              // Capturar el error sin hacer nada
            }
          }
        }
      };
  
      await findCv();
    };
  
    checkCvAvailability();
  }, [filename]);

  return (
    <>
      {showContainer && (
        <div
          className={
            cvFound ? "approvedContainer cvFound" : "approvedContainer"
          }
        >
          {cvFound ? (
            <div className="approvedItem2">
              <div className="approvedText2">
                <p>
                  <strong>SU CV</strong>
                </p>
                <img className="gif" src={check} alt="check"></img>
              </div>
              <p>Se envió correctamente!</p>
              <img
                className="trianEnvio"
                src={triangulos}
                alt="triangulos"
              ></img>
            </div>
          ) : (
            <div className="approvedItem">
              <img className="gif" src={MNMGIF} alt="mnmgif"></img>
              <div className="approvedText">
                <p>
                  <strong>AGUARDE</strong>
                </p>
                <p>Un momento</p>
              </div>
              <img
                className="trianEnvio"
                src={triangulos}
                alt="triangulos"
              ></img>
            </div>
          )}
        </div>
      )}
    </>
  );
};
