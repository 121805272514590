import React from 'react';

const Triangles = () => {
    return (
        <div className='containerTriangles'>
            <div className="triangles"></div>
        </div>
    );
}

export default Triangles;
