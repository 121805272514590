import React from "react";
import { Link } from "react-router-dom";

import activity1 from "../../../../assets/activity1.png";
import activity2 from "../../../../assets/activity2.png";
import activity3 from "../../../../assets/activity3.png";
import activity4 from "../../../../assets/activity4.png";
import activity5 from "../../../../assets/activity5.png";
import activity6 from "../../../../assets/activity6.png";
import activity7 from "../../../../assets/activity7.png";
import activity8 from "../../../../assets/activity8.png";
import mas from "../../../../assets/mas.png";

export const QueHacemos = () => {
  let activities = [
    {
      img: activity1,
      text: "Comunicacion interna y externa",
      type: "comunicacion",
    },

    {
      img: activity2,
      text: "Campañas publicitarias on y offline",
      type: "comunicacion",
    },
    {
      img: activity3,
      text: "Producción audiovisual y fotográfica",
      type: "comunicacion",
    },
    { img: activity4, text: "Consultoría y asesoria", type: "comunicacion" },
    { img: activity5, text: "Gestion de sitios web", type: "marketing" },

    { img: activity6, text: "Cursos y capacitaciones", type: "marketing" },
    { img: activity7, text: "Comunicacion de crisis", type: "marketing" },
    { img: activity8, text: "Social Media", type: "marketing" },
  ];
  return (
    <div className="activitiesContainer">
      <div className="box">
        <h3 className="activityTitle">¿Que hacemos?</h3>
        <div className="activitiesList">
          {activities.map((a, index) => {
            const link =
              a.type === "comunicacion" ? "/comunicacion" : "/marketing";
            return (
              <Link to={link} key={index} style={{ textDecoration: "none" }}>
                <div className="activityItem">
                  <img className="activityImg" src={a.img} alt="activities" />
                  <p className="activityText">{a.text}</p>
                </div>
              </Link>
            );
          })}
        </div>
        <Link to={"/contacto"} style={{ textDecoration: "none" }} className="LinkQueHacemos">
          <button className="buttonActivity">
            <img src={mas} alt="mas actividades" className="imgMas"></img>
            <h6 className="h6Activity">Quiero saber mas!</h6>
          </button>
        </Link>
      </div>
    </div>
  );
};
