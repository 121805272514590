import React from 'react';
import circles from '../../../../assets/circle.png'

const WeMNM = () => {
    return (
        <div className='weMNM'>
            <div className="title-about">
                <h3>Nosotros</h3>
            </div>
            <div className="container-about">
                <div className="image-ab">
                    <img src={circles} alt="Imagen de círculos" />
                </div>
                <div className="text-aboutus">
                    <h5>Minimal Agency</h5>
                    <p>
                    Somos Guille y Damian, fundadores de MINIMAL. 
                    <br /><br />
                    Creamos proyectos de comunicación concreta con el objetivo de proponer un enfoque minimalista a los procesos, con la premisa de optimizar los recursos y maximizar los resultados.
                    <br /><br />
                    Nos proponemos trabajar desde la flexibilidad y en un ambiente desestructurado, con metas claras y siempre actualizados en una industria en permanente cambio. Buscamos transmitir una cultura de trabajo solidaria y comprometida, que aporte soluciones dinámicas y efectivas. 
                    </p>
                </div>
            </div>
        </div>
    );
}

export default WeMNM;
