import { React, useState } from "react";
import { sendEmail } from "../../../../utils/firebase";
//imagenes
import blackfacebook from "../../../../assets/blackfacebook.png";
import blackinstagram from "../../../../assets/blackinstagram.png";
import blacklinkedin from "../../../../assets/blacklinkedin.png";
import { Approved } from "../../Approved/Approved2";

const Formcontact = () => {
  const [approved, setApproved] = useState(false);
  const [id, setId] = useState(null);

  function submitHandler(e) {
    e.preventDefault();

    let name = e.target.name.value;
    let mobile = e.target.mobile.value;
    let email = e.target.email.value;
    let subject = e.target.subject.value;
    let text = e.target.text.value;

    sendEmail(name, email, mobile, subject, text).then((result) => {
      setId(result);
      setApproved(true);
    });
  }

  return (
    <>
      <div className="containerForm">
        <div className="titleFormcontact">
          <h3>Contrata nuestros servicios</h3>
        </div>
        <form className="formContact" onSubmit={submitHandler}>
          <div className="div-form">
            <section className="sectionsform">
              <label htmlFor="nombre">Nombre</label>
              <input type="text" name="name" id="nombre" placeholder="Ej: Minimal" multiple required/>
            </section>
            <section className="sectionsform">
              <label htmlFor="numtelefono">Teléfono</label>
              <input type="tel" name="mobile" id="numtelefono" placeholder="Ej: 11 6620-0046" multiple required/>
            </section>
          </div>
          <div className="div-form">
            <section className="sectionsform">
              <label htmlFor="correo">Correo electrónico</label>
              <input type="email" name="email" id="correo" placeholder="Ej: Minimal@agency.com" multiple required/>
            </section>
            <section className="sectionsform">
              <label htmlFor="options">Servicios</label>
              <select className="optionsform" name="subject" id="options" required>
                <option value="" id="primeropcion">Ej: Elegí un servicio</option>
                <option value="Social media">Social media</option>
                <option value="Publicidad digital">Publicidad digital</option>
                <option value="Campañas on y offline">Campañas on y offline</option>
                <option value="Gestion de sitios web">Gestion de sitios web</option>
                <option value="Comunicación interna o externa">Comunicación interna o externa</option>
                <option value="Social media">Social media</option>
                <option value="Comunicación de crisis">Comunicación de crisis</option>
                <option value="Relaciones con la comunidad"> Relaciones con la comunidad</option>
                <option value="Consultoría y asesoría">Consultoría y asesoría</option>
                <option value="Producciones y realización audiovisual">Producciones y realización audiovisual</option>
              </select>
            </section>
          </div>
          <div className="div-form">
            <section className="sectionsform">
              <label htmlFor="mensaje">Mensaje</label>
              <textarea className="messageFrm" name="text" id="mensaje" cols="30" rows="10" placeholder="Ej: Me gustaría una propuesta..." required></textarea>
              <button type="submit" style={{color: "#222"}}>Enviar Correo</button>
            </section>
            <section className="socialContainer">
              <div className="socialcontactFORM">
                <div className="itemIcon">
                  <img src={blackfacebook} alt="Facebook Icon" />
                  <a href="https://www.facebook.com/minimal.cm" target="_blank"rel="noopener noreferrer"> Minimal.cm </a>
                </div>
                <div className="itemIcon">
                  <img src={blackinstagram} alt="Instagram Icon" />
                  <a href="https://www.instagram.com/minimal.cm/" target="_blank"rel="noopener noreferrer"> Minimal.cm </a>
                </div>
                <div className="itemIcon">
                  <img src={blacklinkedin} alt="Linkedin Icon" />
                  <a href="https://www.linkedin.com/company/minimalagency/" target="_blank"rel="noopener noreferrer">Minimalagency </a>
                </div>
              </div>
              <div className="avisoSeguinos">
                <h4>¡Seguinos en nuestras redes sociales!</h4>
              </div>
            </section>
          </div>
        </form>
        {approved ? <Approved id={id}></Approved> : null}
      </div>
    </>
  );
};

export default Formcontact;
