import React from 'react';

import videoHome from '../../../../assets/videoHome.mp4';
import flecha from '../../../../assets/arrow.png';
const Initial = () => {
    return (
        <div className='front-page'>
            <div className='videoInitial'>
                <video className='videoHome' muted autoPlay loop>
                    <source src={videoHome} type='video/mp4'/>
                </video>
            </div>
            <div className='text-front'>
                <div className="up-home">
                    <div className='titleInitial'>
                        <h1>MINIMAL AGENCY</h1>
                    </div>
                    <div className='textInitial'>
                        <p>Diseñamos y desarrollamos proyectos específicos para resolver las necesidades comunicacionales de nuestros clientes.</p>
                    </div>
                </div>
                    <div className='bar-animation'>
                        <section className='bar-bar'>
                            <div className="bar-progress"></div>
                        </section>
                        <section className='arrow-animation'>
                            <img src={flecha} alt="Flecha animada" />
                        </section>
                    </div>

                <div className="cards-front">
                    <section className="title-cards-front">
                        <h3>Nuestros valores</h3>
                    </section>
                    <section>
                        <div className="card-front">
                            <h5>Confianza</h5>
                            <p>Establecemos relaciones sólidas y duraderas con nuestros clientes, basadas en la transparencia, la honestidad y la confianza mutua. 
                            <br /> Minimal es confianza.</p>
                        </div>
                        <div className="card-front">
                            <h5>Compromiso</h5>
                            <p>Estamos comprometidos con los objetivos y necesidades de nuestros clientes bajo la premisa de trabajar de manera colaborativa para lograr soluciones.</p>
                        </div>
                        <div className="card-front">
                            <h5>Creatividad</h5>
                            <p>Todo cliente necesita diferenciación.
                            <br/>Nos ocupamos de trabajar proyectos creativos que brinden soluciones únicas para nuestros clientes.</p>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Initial;
