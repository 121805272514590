import React, { useState, useEffect } from "react";
import MNMGIF from "../../../assets/MNMGIF.gif";
import check from "../../../assets/check.gif";
import triangulos from "../../../assets/trianenvio.png";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../../utils/firebase";

export const Approved = ({ id }) => {
  const [formFound, setFormFound] = useState(false);
  const [showContainer, setShowContainer] = useState(true);

  useEffect(() => {
    const collectionRef = collection(db, "correos"); // Reemplaza 'nombre_de_la_coleccion' con el nombre de tu colección
    const documentId = id; // Reemplaza 'ID_DEL_DOCUMENTO' con el ID del documento que deseas buscar
    const maxRetries = 5; // Número máximo de intentos
    let retries = 0; // Contador de intentos
    const retryDelay = 2000; // 2 segundos
    const checkDocumentAvailability = async () => {
      const findForm = async () => {
        try {
          const documentSnapshot = await getDoc(doc(collectionRef, documentId));
          if (documentSnapshot.exists()) {
            // El documento existe en la colección
            setFormFound(true);
            setTimeout(() => {
              setShowContainer(false);
            }, 5000);
          }
        } catch (error) {
          // El archivo no existe en Firebase Storage
          retries++;
          if (retries < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            await findForm();
          }
        }
      };

      await findForm();
    };
    checkDocumentAvailability();
  }, [id]);

  return (
    <>
      {showContainer && (
        <div
          className={
            formFound ? "approvedContainer cvFound" : "approvedContainer"
          }
        >
          {formFound ? (
            <div className="approvedItem2">
              <div className="approvedText3">
                <p>
                  <strong>SU FORMULARIO</strong>
                </p>
                <img className="gif" src={check} alt="check"></img>
              </div>
              <p>Se envió correctamente!</p>
              <img
                className="trianEnvio"
                src={triangulos}
                alt="triangulos"
              ></img>
            </div>
          ) : (
            <div className="approvedItem">
              <img className="gif" src={MNMGIF} alt="mnmgif"></img>
              <div className="approvedText">
                <p>
                  <strong>AGUARDE</strong>
                </p>
                <p>Un momento</p>
              </div>
              <img
                className="trianEnvio"
                src={triangulos}
                alt="triangulos"
              ></img>
            </div>
          )}
        </div>
      )}
    </>
  );
};
