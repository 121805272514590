import React, { useEffect, useState } from "react";
import { firestore } from "../../../../utils/firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import arrows
import nextArrowImage from "../../../../assets/arrowSliderNext.png";
import prevArrowImage from "../../../../assets/arrowSliderPrev.png";

const Brands = () => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storage = getStorage(); // Obtiene una instancia de Firebase Storage
        const imagesRef = firestore.collection("brands"); // Obtiene una referencia a la colección 'brands' en Firestore
        const snapshot = await imagesRef.get(); // Obtiene un snapshot de los documentos en la colección

        const fetchedImages = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const storageRef = ref(storage, doc.data().imagePath); // Crea una referencia al archivo de imagen en Firebase storage
            const imageUrl = await getDownloadURL(storageRef); // Obtiene la URL de descarga de la imagen utilizando la referencia al archivo en Firebase Storage
            return { id: doc.id, url: imageUrl }; // Crea un objeto con el ID del documento y la URL de la imagen
          })
        );

        setImages(fetchedImages); // Actualiza el estado con las imágenes obtenidas
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages(); // Llama a la función para obtener las imágenes al montar el componente
  }, []);

  const PrevArrow = ({ onClick }) => (
    <img
      className="arrow prev-arrow"
      src={prevArrowImage}
      alt="Previous"
      onClick={onClick}
    />
  );

  const NextArrow = ({ onClick }) => (
    <img
      className="arrow next-arrow"
      src={nextArrowImage}
      alt="Next"
      onClick={onClick}
    />
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    swipeScrollTolerance: 20,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1500, // Añadido nuevo breakpoint en 1020px
        settings: {
          slidesToShow: 5, // Mostrar 5 slides para min-width: 1020px
        },
      },
      {
        breakpoint: 1300, // Añadido nuevo breakpoint en 1020px
        settings: {
          slidesToShow: 4, // Mostrar 5 slides para min-width: 1020px
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Mostrar 3 slides para min-width: 1024px
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Mostrar 2 slides para min-width: 768px
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Mostrar 1 slide para max-width: 767px
        },
      },
    ],
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    // <div className='carousel'>
    //   <div className="tit-clientes">
    //     <h3>Marcas que confían en nosotros</h3>
    //   </div>
    //   <div className="container-carousel">
    //   <div className="peque" >
    //       {images.length > 0 ? (
    //         images.map((image) => (
    //           <div className="carousel-item" key={image.id}>
    //             <img className="imgCarousel" src={image.url} alt="Imagen de marcas que confían en nosotros" />
    //           </div>
    //         ))
    //       ) : (
    //         <p>Cargando...</p>//En el caso de que no haya imagenes se muestra esto por pantalla
    //       )}
    //     </div>
    //     <div className="carousel-navigation">
    //       <button className="prev-button">
    //         <img src={arrowPrev} alt="Anterior" />
    //       </button>
    //       <button className="next-button">
    //         <img src={arrowNext} alt="Siguiente" />
    //       </button>
    //     </div>
    //   </div>

    // </div>
    <div className="brandsContainer">
      <div className="tit-clientes">
        <h3>Marcas que confían en nosotros</h3>
      </div>
      <div className="sliderSetUpContainer">
        <Slider {...settings}>
          {images.map((img, index) => {
            return (
              <div key={index}>
                <div className="slideContainer">
                  <img
                    className="slideItem"
                    src={img.url}
                    alt="imageSlide"
                  ></img>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Brands;
