import React from "react";
import MNM from "../../assets/MNM.png";
import Facebook from "../../assets/facebook.png";
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";
import Email from "../../assets/email.png";
import Triangles from "../Triangles/Triangles";
import mensaje from "../../assets/mensajeria.png";
import telefono from "../../assets/telefono.png";
import ubicacion from "../../assets/ubicacion.png";
import { submitEmailFiresbase } from "../../utils/firebase";

const Footer = () => {
  const submitEmail = (e) => {
    e.preventDefault();
    let email = document.getElementById("emailFooter").value;
    if(email != ""){
      submitEmailFiresbase(email);
      document.getElementById("emailFooter").value = "";
      /* email.value = ""; */
    } 
  };

  return (
    <footer>
      <div className="footerContainer">
        <div className="logoContainer">
          <img src={MNM} alt="Minimal Agency Logo" />
        </div>
        <div className="socialNetworksContainer">
          <p className="socialNetworksTitle">¡SEGUINOS EN NUESTRAS REDES!</p>
          <div>
            <div className="facebookItem socialItem">
              <a
                href="https://www.facebook.com/minimal.cm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} alt="Facebook Icon" />
              </a>
            </div>
            <div className="linkedinItem socialItem">
              <a
                href="https://www.linkedin.com/company/minimalagency/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Linkedin} alt="Linkedin Icon" />
              </a>
            </div>
            <div className="instagramItem socialItem">
              <a
                href="https://www.instagram.com/minimal.cm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="contactoInfo">
          <section className="sectionContainer">
            <section className="sectionUpper">
              <h5>CONTACTANOS</h5>
              <h6>
                <img src={telefono} alt="telefono"></img>3487-200235 //
                3487-329340
              </h6>
              <h6>
                <img src={mensaje} alt="mensaje"></img>
                contacto@minimalagency.com.ar
              </h6>
              <h6>
                <img src={ubicacion} alt="mensaje"></img>Buenos Aires, Argentina.
              </h6>
            </section>
            <section className="sectionLower">
              <input
                className="inputEmail"
                type="text"
                placeholder="Envianos tu mail y te contactamos"
                name="emailFooter"
                id="emailFooter"
              />
              <img src={Email} alt="Email icon" onClick={submitEmail} />
            </section>
          </section>
        </div>
      </div>
      <span>
        &copy; Copyright 2023 - Equipo CoderHouse | Todos los derechos
        reservados
      </span>
      <Triangles />
    </footer>
  );
};

export default Footer;
