import { getFirestore, collection, addDoc } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//Configurado con el de minimal ag
const firebaseConfig = {
  apiKey: "AIzaSyCwjoLHKgXctgl5V4rEhyvDZhD_n9cZnZA",
  authDomain: "minimal-agency.firebaseapp.com",
  projectId: "minimal-agency",
  storageBucket: "minimal-agency.appspot.com",
  messagingSenderId: "972735907776",
  appId: "1:972735907776:web:686ddc640a251e2dce6a25",
  measurementId: "G-XSP4DFE1QN"
};

const app = firebase.initializeApp(firebaseConfig);

const storage = app.storage();

const firestore = app.firestore();

export const db = getFirestore(app);

export {storage, firestore };

export default app;


//FUNCIONES
//Subir curriculum vitae

//cargar el archivo
export  const HandleCv = async (e, setFileCv) =>{
  try{
    if (e.target.files[0]) {
      setFileCv(e.target.files[0]);
    }
  }
  catch{
    throw new Error();
  }
}

//Subir a traves del boton
export const UploadCv = async(fileCv) =>{
    try{
        const uploadTask = storage.ref(`cv_files/${fileCv.name}`).put(fileCv);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("cv_files")
          .child(fileCv.name)
          .getDownloadURL()
          .then((url) => {
            //Mensaje de que se envio
          });
      }
    );
    }
    catch{
        throw new Error();
    }
}

//Enviar email

export async function sendEmail(name, email, mobile, subject, body) {
	const collectionRef = collection(db, 'correos');
	const emailContent = {
		message: {
			text: {
        Nombre: name,
        Correo: email,
        Número: mobile,
        Asunto: subject,
        Mensaje: body
      },
		},
	};
  const docRef = await addDoc(collectionRef, emailContent)
	return docRef.id;
}


export async function submitEmailFiresbase(email) {
	const collectionRef = collection(db, 'mails');
  const mail={
     mail: email
  }
	return await addDoc(collectionRef, mail);
}