import React from "react";
import { Link } from "react-router-dom";
const CardsServices = ({ cards }) => {
  return (
    <div className="cardsContainer">
      {cards.map((card, index) => {
        return (
          <div className="cardContainer" key={index}>
            <Link to={"/contacto"} style={{textDecoration: "none"}}>
              <div className="imgContainerCard">
                <img src={card.img} alt="imgCard" className="imgCard"></img>
                <div className="contactanosEffect">
                  <h6 className="contactanosh6Effect">Contactanos!</h6>
                </div>
              </div>
            </Link>
            <div className="cardInfo">
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};  

export default CardsServices;
