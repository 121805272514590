import React from 'react';
import whatsapp from '../../assets/whatsapp.png';

const Whatsapp = () => {
    return (
        <div className='whatsapp'>
            <a href="https://api.whatsapp.com/send?phone=%2B543487200235&text=Hola+equipo+de+Minimal%2C+quisiera+hacerles+una+consulta." target="_blank" rel="noopener noreferrer">
            <img src={whatsapp} alt="Icono de WhatsApp" />
            </a>
        </div>
    );
}

export default Whatsapp;
