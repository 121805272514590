import {React, useState} from "react";
import { Link } from "react-router-dom";
import MNM from "../../assets/MNM.png";
import Hamb from "../../assets/burger.png"
import Sections from "./Sections/Sections";
/* import arrowNav from "../../assets/arrowNavbar.png"; */

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header>
      <nav>

        <div className="div-after-nav">
          <div className="logo">
            <Link className="iconContainer" to={"/"} onClick={closeMenu}>
                <img className="iconItem" src={MNM} alt="Minimal Agency Logo" />
            </Link>
          </div>
          <div className={`sections ${isOpen && "open"}`}>
            <Sections  closeMenu={closeMenu}/>
          </div>
        </div>

        <div className={`barmenu `} onClick={ () => setIsOpen(!isOpen)}>
          <img src={Hamb} alt="Menu hamburguesa desplegable" />
        </div>

      </nav>
    </header>
  );
};

export default Navbar;
