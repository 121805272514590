import React, { useState } from "react";
import sumMinimal from "../../../../assets/SumMinimal.png";
import sumicon1 from "../../../../assets/sum1.png";
import sumicon2 from "../../../../assets/sum2.png";
import { HandleCv, UploadCv } from "../../../../utils/firebase";
import upload from "../../../../assets/uploadregular.png";
import { Approved } from "../../Approved/Approved";

const SumMinimal = () => {
  //Logica para subir cv
  const [fileCv, setFileCv] = useState(null);
  const [approved, setApproved] = useState(false);

  const handleCvChange = (e) => {
    HandleCv(e, setFileCv);
  };
  const handleUpload = () => {
    if(fileCv){
      UploadCv(fileCv);
      setApproved(true)
    }
    else{
      console.error('No se ha seleccionado ningún archivo para subir.');
    }
  };
  return (
    <div className="SumMinimalContainer">
      <div className="SumMinimal">
        <h3 className="titleSumMinimal">Sumate a nuestro equipo</h3>
        <div className="sumMinimalInfo">
          <img
            className="imgSumMinimal"
            src={sumMinimal}
            alt="Sumate a Minimal"
          ></img>
          <div>
            <div className="sumMinimalUpper">
              <p className="p1">
                En MINIMAL somos apasionados por la comunicación y la diversidad. <br />
                Nos encantaría conocerte y que seas parte de este equipo de creadores.
              </p>
              <p className="p2">
                ¡Envía tu currículum a minimalagency.zarate@gmail.com!
              </p>
            </div>
            <div className="sumMinimalLower">
              <label htmlFor="uploadCv" className="fileButton">
                <div className="buttonCv">
                  <p>Sube CV</p>
                  <div className="iconUpload">
                    <div className="sumicon2Contain">
                      <img className="sumicon2" src={sumicon2} alt="icon"></img>
                    </div>
                    <div className="sumicon1Contain">
                      <img className="sumicon1" src={sumicon1} alt="icon"></img>
                    </div>
                  </div>
                </div>
              </label>
              {fileCv ? (
                <p className="fileName">{fileCv.name}</p>
              ) : (
                <p className="fileName">Seleccione archivo.</p>
              )}
              <input
                type="file"
                id="uploadCv"
                onChange={handleCvChange}
                required
                hidden
              />
              <button className="buttonUpload" onClick={handleUpload}>
                <img src={upload} alt="uploadButton" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {approved ? (
          <Approved filename={fileCv.name}></Approved>
      ) : null}
    </div>
  );
};

export default SumMinimal;
